.button {
    border: 0;
    border-radius: 0.8rem;

    font-family: var(--font-primary);
    font-weight: 600;
    color: var(--white);

    cursor: pointer;
    user-select: none;

    transition: background 0.3s ease-out, color 0.3s ease-out;

    /* responsive */

    min-width: 10rem;
    padding: 1.6rem 1.6rem;

    font-size: 1.6rem;
    line-height: 2.4rem;
}

.button--compact {
    /* responsive */

    padding: 1rem 1.6rem;

    font-size: 1.6rem;
    line-height: 1.6rem;
}

.button--primary {
    background-color: var(--indigo);
}

.button--primary:hover {
    background: rgba(99, 93, 255, 0.85);
}

.button--secondary {
    background-color: var(--white);
    color: var(--black);
}

.button--secondary:hover {
    background: rgba(255, 255, 255, 0.85);
}

@media only screen and (max-width: 480px) {
    .button {
        /* responsive */

        min-width: 0.8rem;
        padding: 1rem 1.2rem;

        font-size: 1.3rem;
        line-height: 2.2rem;
    }

    .button--compact {
        /* responsive */

        padding: 0.6rem 1rem;

        font-size: 1.3rem;
        line-height: 2rem;
    }
}

.silver {
    background-color: silver;
    background: silver;
}

.button__login{
    background-color: #fcb900 !important; /* GREEN */
    width: 100px;
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    color: black;
    text-decoration: none;
    /* display: inline-block; */
    font-size: 14px;
    cursor: pointer;
    border-radius: 10px;
    font-family: Overpass-Bold;
    outline: 3px solid black;
    outline-radius: 10px;
    outline-offset: -6px;
    text-transform: uppercase;
}

.button__logout {
    background-color: #fcb900 !important; /* GREEN */
    width: 100px;
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    color: black;
    text-decoration: none;
    /* display: inline-block; */
    font-size: 14px;
    cursor: pointer;
    border-radius: 10px;
    font-family: Overpass-Bold;
    outline: 3px solid black;
    outline-radius: 10px;
    outline-offset: -6px;
    text-transform: uppercase;
}

.button__get-rates {
    background-color: #fcb900 !important; /* GREEN */
    width: 100px;
    height: 40px;
    /*margin-left: 10px;*/
    /*margin-right: 10px;*/
    border: none;
    color: black;
    text-decoration: none;
    /* display: inline-block; */
    font-size: 14px;
    cursor: pointer;
    border-radius: 10px;
    font-family: Overpass-Bold;
    outline: 3px solid black;
    outline-radius: 10px;
    outline-offset: -6px;
    text-transform: uppercase;
}

.button__logout-mobile {
    background-color: #fcb900 !important; /* GREEN */
    width: 100px;
    height: 30px;
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    color: black;
    text-decoration: none;
    /* display: inline-block; */
    font-size: 14px;
    cursor: pointer;
    border-radius: 10px;
    font-family: Overpass-Bold;
    outline: 3px solid black;
    outline-radius: 10px;
    outline-offset: -6px;
    text-transform: uppercase;
}

.button__rates_list {
    /*background-color: #fcb900 !important; !* GREEN *!*/
    width: 100px;
    height: 20px;
    margin-left: 10px;
    margin-right: 10px;
    color: black;
    font-size: 12px;
    cursor: pointer;
    border-radius: 4px;
    font-family: Overpass-Light;
    outline: 2px solid black;
    /*outline-radius: 15px;*/
    /*outline-offset: -5px;*/
    text-transform: uppercase;
}


.bxt {
margin-top: 3rem;
}
.button__settings-save {
    background-color: #fcb900 !important; /* GREEN */
    width: 100px;
    height: 35px;
    margin-left: 10px;
    margin-right: 10px;
    border: none;
    color: black;
    text-decoration: none;
    /* display: inline-block; */
    font-size: 14px;
    cursor: pointer;
    border-radius: 10px;
    font-family: Overpass-Bold;
    outline: 2px solid black;
    outline-radius: 10px;
    outline-offset: -6px;
    text-transform: uppercase;
}


.button__login-signup {

    min-width: 8.4rem;

    border: 0.2rem solid black;
    color: black;
    background: silver;
    width: 100px;
    font-size: 1.6rem;
    margin-right: 1.6rem;

    font-family: var(--font-primary);
    font-style: normal;
    font-weight: 500;
    line-height: 1.2rem;
    padding: 0.8rem 0;
    border-radius: 0.8rem;
    text-align: center;

    cursor: pointer;
    user-select: none;
    transition: background 0.3s ease-out, color 0.3s ease-out;
}

.button__sign-up {
    background-color: darkgray !important; /* GREEN */
    width: 100px;
    color: black;
    font-size: 14px;
    cursor: pointer;
    border-radius: 10px;
    font-family: Overpass-Bold;
    outline: 3px solid black;
    outline-radius: 10px;
    outline-offset: -6px;
    text-transform: uppercase;
}

.button__sign-up-mobile {
    background-color: darkgray !important; /* GREEN */
    width: 100px;
    height: 40px;
    color: black;
    font-size: 14px;
    cursor: pointer;
    border-radius: 10px;
    font-family: Overpass-Bold;
    outline: 3px solid black;
    outline-radius: 10px;
    outline-offset: -6px;
    text-transform: uppercase;
}

.button__login:last-child,
.button__logout:last-child,
.button__sign-up:last-child {
    margin-right: 0;
}

@media only screen and (min-width: 641px) {
    .button__login,
    .button__logout,
    .button__sign-up {
        padding: 1.0rem 1.0rem;
        font-size: 1.5rem;
        line-height: 1.6rem;
    }
}

.more_style {
    width: 10%;
}


.more_style_price_buttons {
    font-size: 11px;
    width: 110px;
    margin: 5px;
    padding-left: 0px;
    line-height: 12px;
    padding-right: 10px;
    /*width: 100%;*/
}
