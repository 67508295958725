.page-layout {
  display: flex;
  flex-direction: column;
  align-items: center;

  height: 100%;
  width: 100%;
}

.page-layout__content {
  /*flex: 1;*/
  /*flex-basis: auto;*/
  /*flex-shrink: 0;*/
  /*display: flex;*/
  /*flex-direction: column;*/
  /**/
  margin-top: 0.1rem;
  /*max-width: 120rem;*/
  height: 100%;
  width: 100%;
}

@media only screen and (max-width: 640px) {
  .page-layout__content {
    margin-top: 6.4rem;
  }
}
