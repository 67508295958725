.loader {
  height: 4rem;
  width: 4rem;

  margin: auto;

  animation: spin 2s infinite linear;
}

.loader-text {
  height: 4rem;
  width: 4rem;
  font-size: 16px;
  margin: auto;
  text-align: center;
  margin-left: 22px;
}

.truckloader {
  height: 25px;
  width: 75px;

  /*margin: auto;*/

  /*animation: spin 2s infinite linear;*/
}

@keyframes spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
