.profile-grid {
  /*display: flex;*/
  /*flex-direction: column;*/

  /* responsive */
/*margin-left: 800px;*/
  /*margin-top: 48px;*/
}

.profile__header {
  /*float: right;*/
  /*align-content: inherit;*/
  /*display: grid;*/
  /*grid-template-columns: auto 1fr;*/
  /*column-gap: 16px;*/
}

.profile__avatar {
  border: solid 2px var(--aqua);
  border-radius: 50%;

  /* responsive */

  height: 80px;
  width: 80px;
}

.profile__headline {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

.profile__title {
  margin-top: 0;
  margin-bottom: 0;

  color: var(--white);
}

.profile__details {
  /* responsive */

  margin-top: 32px;
}

@media only screen and (max-width: 480px) {
  .profile-grid {
    margin-top: 2.4rem;
  }

  .profile__avatar {
    height: 5.6rem;
    width: 5.6rem;

    border-radius: 50%;

    border: solid 0.13rem var(--aqua);
  }

  .profile__title {
    font-size: 1.6rem;
  }

  .profile__description {
    font-size: 1.3rem;
  }

  .profile__details {
    margin-top: 1.6rem;
  }
}
